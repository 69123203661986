import {Fragment, useState} from "react";
import {Editor} from "react-draft-wysiwyg";
import {EditorState, convertToRaw} from "draft-js";
import draftToHtml from "draftjs-to-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "../components.scss";

const EditorWrapper = (props) => {
    const {
        toolbarCls, wrapperCls, editorCls,
        placeholder, method, label, isCount,
        maxCount
    } = props;

    const [editorState, setEditorState] = useState(() => EditorState.createEmpty());
    const [wordCount, setWordCount] = useState(0);

    const handleChange = (editorState) => {
        const currentContentAsHTML = draftToHtml(convertToRaw(editorState.getCurrentContent()));
        const word = currentContentAsHTML.replace(/(<([^>]+)>)/gi, "").length;
        setWordCount(word - 1);
        if (word < maxCount) {
            setEditorState(editorState);
            method(currentContentAsHTML);
        }
    };
    return <Fragment>
        {
            (label || isCount) && <div className="editor-label">
                {label && <label htmlFor={label}>{label}</label>}
                {isCount && <div><span className={wordCount >= maxCount ? `than` : `less`}>{wordCount >= maxCount ? maxCount : wordCount}</span>/<span>{maxCount}</span></div>}
            </div>
        }
        <Editor
            toolbarClassName={`toolbarClassName ${toolbarCls ?? ""}`}
            wrapperClassName={`wrapperClassName ${wrapperCls ?? ""}`}
            editorClassName={`editorClassName ${editorCls ?? ""}`}
            localization={{locale: 'ru',}}
            id={label}
            placeholder={placeholder}
            editorState={editorState}
            onEditorStateChange={handleChange}
        />
    </Fragment>
};

export default EditorWrapper;