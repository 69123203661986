import {Fragment} from 'react';
import '../components.scss';

const Input = (props) => {
    const {
        cls, type = 'text', label,
        labelCls, text, name,
        value, inputType, width = '100%',
        method, onBlur, margin = [0, 0, 0, 0],
        disabled=false, onSearchSubmit, icon,
        onKeyPress, maxLength, minLength, isOnKey=false,
        readOnly=false, onClick, mask, children,
        autoComplete
    } = props;

    return (
        <Fragment>
            <div className={`input ${cls ? cls : ''}`}>
                <div className={`label ${labelCls ? labelCls : ''}`}>
                    <label htmlFor={label}>{label}</label>
                </div>
                <input
                    onChange={method}
                    onClick={onClick}
                    onBlur={onBlur}
                    id={label}
                    type={type}
                    name={name}
                    disabled={disabled}
                    readOnly={readOnly}
                    placeholder={text}
                    onKeyPress={onKeyPress}
                    onKeyUp={e => isOnKey && e.key === 'Enter' && onSearchSubmit()}
                    maxLength={maxLength}
                    minLength={minLength}
                    style={{
                        margin: `${margin[0]}px ${margin[1]}px ${margin[2]}px ${margin[3]}px`,
                        width: width,
                        borderRadius: inputType === 'search' && '6px 0px 0px 6px'
                    }}
                    autoComplete={autoComplete}
                    mask={mask}
                    value={value}/>
                    {
                        children
                    }
                {
                    icon ?? null
                }
                {
                    inputType
                        ? <button className="input__btn" onClick={onSearchSubmit} type='button'>
                            {inputType === 'search' && <img src="/assets/icons/search.svg" alt=""/>}
                        </button>
                        : null
                }
            </div>
        </Fragment>
    )
}

export default Input;