import {useEffect, useRef} from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import '../../components.scss';

am4core.useTheme(am4themes_animated);
am4core.addLicense("ch-custom-attribution")

const Bar = ({items}) => {
    const chart = useRef(null);

    useEffect(() => {
        let bar = am4core.create("bar", am4charts.XYChart);

        bar.data = items?.map(item => {
            return {
                district: item.district,
                members_amount: item.members_amount,
            }
        });

        const maxMembersAmount = items?.length > 0 ? Math.max(items?.map(item => item.members_amount).reduce((a, b) => a > b ? a : b)) : 0;
        const minMembersAmount = items?.length > 0 ? Math.max(items?.map(item => item.members_amount).reduce((a, b) => a > b ? b : a)) : 0;

        const valueAxis = bar.yAxes.push(new am4charts.ValueAxis());
        valueAxis.renderer.grid.template.stroke = am4core.color("#8A8EAD");
        valueAxis.renderer.grid.template.strokeWidth = 2;
        valueAxis.renderer.stroke = am4core.color("#8A8EAD");
        valueAxis.renderer.labels.template.disabled = true;
        valueAxis.tooltip.disabled = true;

        const categoryAxis = bar.xAxes.push(new am4charts.CategoryAxis());
        categoryAxis.dataFields.category = "district";
        categoryAxis.renderer.grid.template.disabled = true;
        categoryAxis.renderer.grid.template.strokeOpacity = 0;
        categoryAxis.renderer.grid.template.location = 0;
        categoryAxis.renderer.minGridDistance = 25;
        categoryAxis.renderer.minGridDistance = 30;
        categoryAxis.tooltip.disabled = true;

        const label = categoryAxis.renderer.labels.template;
        label.truncate = true;
        label.maxWidth = 120;
        label.tooltipText = "{members_amount}";

        const series = bar.series.push(new am4charts.ColumnSeries());
        series.dataFields.valueY = "members_amount";
        series.dataFields.categoryX = "district";
        series.name = 'District';
        series.strokeWidth = 1;

        const seriesTooltip = series.tooltip;
        seriesTooltip.pointerOrientation = "down";
        seriesTooltip.background.cornerRadius = 50;
        seriesTooltip.background.stroke = 0;
        seriesTooltip.fill = am4core.color("#2B3990");
        seriesTooltip.dy = -15;
        seriesTooltip.label.fontSize = 12;

        const columnsTemplate = series.columns.template;
        columnsTemplate.tooltipText = "{district}";
        columnsTemplate.stroke = am4core.color("#E2F0FF");
        columnsTemplate.fill = am4core.color("#E2F0FF");
        columnsTemplate.width = 20;
        columnsTemplate.strokeOpacity = 0;
        columnsTemplate.column.cornerRadiusTopLeft = 10;
        columnsTemplate.column.cornerRadiusTopRight = 10;
        columnsTemplate.tooltipY = 0;

        categoryAxis.renderer.labels.template.adapter.add("textOutput", (text, target) => {
            if (text) {
                return text.replace(text, `${target.dataItem._dataContext.members_amount}`);
            }
        });

        columnsTemplate.adapter.add("fill", (fill, target) => {
            if (target.dataItem && (target.dataItem.valueY === maxMembersAmount)) {
                return am4core.color("#75F390");
            } else if (target.dataItem && (target.dataItem.valueY === minMembersAmount)) {
                return am4core.color("#FF6060");
            } else {
                return fill
            }
        });

        bar.cursor = new am4charts.XYCursor();

        chart.current = bar;

        return () => {
            bar.dispose();
        }
    }, [items])
    return (
        <div className="bar" id="bar"></div>
    );
};

export default Bar;