import {Fragment} from "react";
import ReactPaginate from "react-paginate";
import {useWindowSize} from "../../hooks";
import '../components.scss';

const Pagination = ({total, limit, changePage}) => {
    const [isResize] = useWindowSize(1024);
    const [isResizeSm] = useWindowSize(424);

    const pageCount = Math.ceil(total/ limit);
    return <Fragment>
        {
            total > 0 && <ReactPaginate
                previousLabel={<Fragment><img src="/assets/icons/arrow-left.svg" alt=""/>{isResize && 'Предыдущая'}</Fragment>}
                nextLabel={<Fragment>{isResize && 'Следующая'}<img className="rotate" src="/assets/icons/arrow-left.svg" alt=""/></Fragment>}
                breakLabel={'...'}
                pageRangeDisplayed={isResize ? 2 : 1}
                marginPagesDisplayed={isResize ? 3 : isResizeSm ? 2 : 1}
                breakClassName={'break-me'}
                pageCount={pageCount}
                onPageChange={changePage}
                containerClassName={'pagination'}
                previousLinkClassName={"pagination__link"}
                nextLinkClassName={"pagination__link"}
                disabledClassName={"pagination__link--disabled"}
                activeClassName={"pagination__link--active"}
            />
        }
    </Fragment>
}

export default Pagination;