import {Fragment} from "react";
import Icon from '../Icon/Icon';
import {LoaderBtn} from "../index";
import '../components.scss';

const Button = (props) => {
    const {
        type = 'button', cls, text,
        icon, method = () => {}, onSubmit, padding = [14, 18, 14, 18],
        margin = [0, 0, 0, 0], disabled = false, loading = false, loaderCls,
        width, height, iconLeft
    } = props;

    return (
        <button
            onClick={method}
            onSubmit={onSubmit}
            type={type}
            className={`btn btn__${cls} ${disabled ? 'btn__disabled' : ''}`}
            disabled={disabled}
            style={{
                padding: `${padding[0]}px ${padding[1]}px ${padding[2]}px ${padding[3]}px`,
                margin: `${margin[0]}px ${margin[1]}px ${margin[2]}px ${margin[3]}px`,
                width,
                height
            }}>
            {loading
                ? <LoaderBtn loaderCls={loaderCls}/>
                : <Fragment>
                    {iconLeft && <Icon name={iconLeft}/>}
                    {text}
                    {icon && <Icon name={icon}/>}
                </Fragment>
            }
        </button>
    )
}

export default Button;