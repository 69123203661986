import {useEffect, useState} from "react";

export default function useWindowSize(innerWidth=768) {
    const [isResize, setIsResize] = useState(window.innerWidth > innerWidth)

    const handleWindowSize = () => window.innerWidth > innerWidth ? setIsResize(true) : setIsResize(false);

    useEffect(() => {
        window.addEventListener('resize', handleWindowSize);
        return () => {
            window.removeEventListener('resize', handleWindowSize);
        };
    }, []);

    return [isResize, setIsResize];
};