import {useHistory} from "react-router-dom";
import {Icon} from "../index";
import "../components.scss";

const Back = () => {
    const router = useHistory();
    return (
        <div
            className='back'
            onClick={() => router.goBack()}
        >
            <Icon
                name="arrow-left"
                isColor={true}
                color={"svg--blue"}
            />
            <span>Назад</span>
        </div>
    );
};

export default Back;