import "../components.scss";

const CheckBox = ({cls, name, disabled, checked, value, onClick, style, title}) => {
    return (
        <label
            className={`checkbox ${cls ? cls : ''}`}
        >
            <input
                type={"checkbox"}
                className="checkbox__input"
                name={name}
                disabled={disabled}
                checked={checked}
                value={value}
                onClick={onClick}
                style={style}
            />
            <span
                className="checkbox__box"
            >
            </span>
            <span className="checkbox__title">
                {
                    title
                }
            </span>
        </label>
    );
};

export default CheckBox;