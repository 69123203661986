import {useCallback, useEffect, useRef, useState} from "react";
import {useDebounce} from "../../hooks";
import Icon from "../Icon/Icon";
import classNames from "classnames";
import '../components.scss';

const Select = (props) => {
    const {
        cls, items, label, text,
        name, maxWidth, margin = [0, 0, 0, 0],
        value = 'null', method = () => {}, disabled,
        isSearch=false, request
    } = props;
    const [isListOpen, setIsListOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState(null);
    const [search, setSearch] = useState("");

    const debouncedSearch = useDebounce(search, 700);

    const listRef = useRef(null);

    const listOpenHandler = e => {
        e.preventDefault();
        setIsListOpen(isListOpen => !isListOpen);
    }

    const optionHandler = (e, name) => {
        e.preventDefault();
        method(e);
        setSelectedOption(name);
        setIsListOpen(false);
    };

    const checkIfClickedOutside = useCallback(e => {
        if (isListOpen && listRef.current && !listRef.current.contains(e.target)) {
            setIsListOpen(false)
        }
    }, [isListOpen]);

    useEffect(() => {
        document.addEventListener("mousedown", checkIfClickedOutside)
        return () => {
            document.removeEventListener("mousedown", checkIfClickedOutside)
        }
    }, [isListOpen, checkIfClickedOutside])

    useEffect(() => {
        if (request) {
            if (debouncedSearch.length > 0) {
                request({lookup: debouncedSearch});
            } else {
                request({lookup: null});
            }
        }
    }, [debouncedSearch]);
    return (
        <section className={`select ${cls ?? ""} ${disabled ? "select__disabled" : ""}`} style={{maxWidth: maxWidth, margin: `${margin[0]}px ${margin[1]}px ${margin[2]}px ${margin[3]}px`}}>
            <h4 className="select__title">
                {
                    label
                }
            </h4>
            <button className={classNames(`select__header`, {active: isListOpen})} onClick={listOpenHandler} disabled={disabled}>
                <div className="select__box">
                    <p className={`select__value ${isListOpen ? 'disabled' : ''}`}>
                        {
                            selectedOption ?? text
                        }
                    </p>
                </div>
                {
                    isListOpen
                        ? <Icon name={"btn-arrow-down"}/>
                        : <Icon name={"btn-arrow-up"} />
                }
            </button>
            {
                isListOpen
                    ? <div
                        className={`select__content ${isListOpen ? 'active' : ''} ${items && items.length > 5 ? 'scroll' : ''}`}
                        ref={listRef}
                        onClick={e => e.stopPropagation()}
                    >
                        {
                            isSearch && <input
                                className={"select__search"}
                                type={"search"}
                                name={"search"}
                                placeholder={"Начать поиск"}
                                value={search}
                                onChange={(e) => setSearch(e.target.value)}
                            />
                        }
                        <button
                            value={null}
                            name={name}
                            data-id={null}
                            data-name={null}
                            data-file={null}
                            onClick={e => optionHandler(e, null)}
                        >
                            Не выбрано
                        </button>
                        {
                            items.length !== 0
                                ? items.map(item =>
                                    <button
                                        key={item.id}
                                        value={item.id}
                                        name={name}
                                        data-id={item.id}
                                        data-name={item.name}
                                        data-value={item.value}
                                        data-file={item.file}
                                        onClick={e => optionHandler(e, item.name ?? item.title)}
                                    >
                                        {item.name ?? item.title}
                                    </button>
                                )
                                : <button>Пусто</button>
                        }
                    </div>
                    : null
            }
        </section>
    )
}

export default Select;