import {userDescription} from "../../utils/user/userDescription";
import Sidebar from "../../Layout/Sidebar/Sidebar";
import Divider from "../Divider/Divider";
import "./BurgerMenu.scss";

export default function BurgerMenu({openBurger, setOpenBurger, user, region, isSidebarHide, setSidebarHide, logout}) {
    return (
        <div className={`burger ${openBurger ? "burger--show" : "burger--hide"}`}>
            <div className={`burger__content ${openBurger ? "burger__content--show" : "burger__content--hide"}`} onClick={() => setOpenBurger(false)}>
                <div className={`burger__menu ${openBurger ? "burger__menu--show" : "burger__menu--hide"}`}>
                    <div className="burger__info">
                        <h6 className="info__name">{user.full_name}</h6>
                        <p className="info__role">{userDescription(user, region)}</p>
                    </div>
                    <Divider cls="burger__divider" color={"#E1E3F1"} height={"1px"}/>
                    <Sidebar user={user} isSidebarHide={isSidebarHide} setSidebarHide={setSidebarHide} setOpenBurger={setOpenBurger} region={region} cls={"header__burger-content"}/>
                    <Divider cls="burger__divider" color={"#E1E3F1"} height={"1px"}/>
                    <div className="burger__close">
                        <button className="close__btn" onClick={logout}>
                            <img className="close__img" src="/assets/icons/exit.svg" alt="exit"/>
                        </button>
                        <p className="close__text" onClick={logout}>Выйти из аккаунта</p>
                    </div>
                </div>
            </div>
        </div>
    );
};