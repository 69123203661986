import {lazy} from "react";

import {
    ACTIVITY_ROUTE,
    AUTH_COORDINATOR_ROUTE,
    AUTH_VOLUNTEER_MANAGER_ROUTE,
    AUTH_METHODIST_ROUTE,
    AUTH_ROUTE,
    EVENTS_ROUTE,
    FEEDBACKS_ROUTE,
    FINANCE_ROUTE,
    FORGOT_PASSWORD_ROUTE,
    MY_FEEDBACK_ROUTE,
    NOTFOUND_ROUTE,
    OLYMPIAD_ROUTE,
    PARTICIPANT_ROUTE,
    PLAN_ROUTE,
    PROFILE_ROUTE,
    PROGRAMS_ROUTE,
    QUESTIONNAIRES_ROUTE,
    VOLUNTEERS_ROUTE,
    AUTH_TRUSTEE_GENERAL_ROUTE,
    AUTH_TRUSTEE_LEGAL_ROUTE,
    AUTH_VOLUNTEER_ROUTE,
    BID_VOLUNTEERS_ROUTE,
    MAILING_ROUTE,
    IMPORT_RESULT_ROUTE,
    AUTH_MENTOR_ROUTE,
    IMPORT_STATISTIC_ROUTE,
    AUTH_GRADUATE_ROUTE,
    MY_RESUME_ROUTE,
    NEWS_ROUTE, LIBRARIES_ROUTE
} from "./consts";

import {
    COORDINATOR_USER,
    GRADUATE_USER, MENTOR_USER,
    METHODIST_USER,
    TRUSTEE_GENERAL_USER,
    TRUSTEE_LEGAL_USER,
    VOLUNTEER_MANAGER_USER,
    VOLUNTEER_USER
} from "../consts/roles";

const Auth = lazy(() => import("../pages/Auth/Auth").then(module => module));
const AuthForm = lazy(() => import("../pages/Auth/AuthForm/AuthForm").then(module => module));
const ForgotPassword = lazy(() => import("../pages/Auth/ForgotPassword/ForgotPassword").then(module => module));
const NotFound = lazy(() => import("../pages/Error/NotFound").then(module => module));
const Profile = lazy(() => import("../pages/Profile/Profile").then(module => module));
const Participants = lazy(() => import("../pages/Participants/Participants").then(module => module));
const Plan = lazy(() => import("../pages/Plan/Plan").then(module => module));
const PlanId = lazy(() => import("../pages/Plan/PlanId/PlanId").then(module => module));
const Olympiads = lazy(() => import("../pages/Olympiads/Olympiads").then(module => module));
const Programs = lazy(() => import("../pages/Programs/Programs").then(module => module));
const Finance = lazy(() => import("../pages/Finance/Finance").then(module => module));
const MyFeedback = lazy(() => import("../pages/MyFeedback/MyFeedback").then(module => module));
const Activity = lazy(() => import("../pages/Activity/Activity").then(module => module));
const Feedbacks = lazy(() => import("../pages/Feedbacks/Feedbacks").then(module => module));
const Questionnaires = lazy(() => import("../pages/Questionnaires/Questionnaires").then(module => module));
const Events = lazy(() => import("../pages/Events/Events").then(module => module));
const Volunteers = lazy(() => import("../pages/Volunteers/Volunteers").then(module => module));
const BidVolunteers = lazy(() => import("../pages/BidVolunteers/BidVolunteers").then(module => module));
const Mailing = lazy(() => import("../pages/Mailing/Mailing").then(module => module));
const ImportResult = lazy(() => import("../pages/ImportResult/ImportResult").then(module => module));
const ImportResultId = lazy(() => import("../pages/ImportResult/ImportResultId/ImportResultId").then(module => module));
const ImportStatistic = lazy(() => import("../pages/ImportStatistic/ImportStatistic").then(module => module));
const MyResume = lazy(() => import("../pages/MyResume/MyResume").then(module => module));
const News = lazy(() => import("../pages/News/News").then(module => module));
const Libraries = lazy(() => import("../pages/Libraries/Libraries").then(module => module));

const authRoutes = [
    {
        path: AUTH_ROUTE,
        Component: Auth
    },
    {
        path: PROFILE_ROUTE,
        Component: Auth
    },
    {
        path: FORGOT_PASSWORD_ROUTE,
        Component: ForgotPassword
    },
    {
        path: AUTH_COORDINATOR_ROUTE,
        Component: AuthForm
    },
    {
        path: AUTH_METHODIST_ROUTE,
        Component: AuthForm
    },
    {
        path: AUTH_VOLUNTEER_MANAGER_ROUTE,
        Component: AuthForm
    },
    {
        path: AUTH_TRUSTEE_GENERAL_ROUTE,
        Component: AuthForm
    },
    {
        path: AUTH_TRUSTEE_LEGAL_ROUTE,
        Component: AuthForm
    },
    {
        path: AUTH_VOLUNTEER_ROUTE,
        Component: AuthForm
    },
    {
        path: AUTH_GRADUATE_ROUTE,
        Component: AuthForm
    },
    {
        path: AUTH_MENTOR_ROUTE,
        Component: AuthForm
    },
    {
        path: NOTFOUND_ROUTE,
        Component: NotFound
    }
];

const privateRoutes = [
    {
        path: PARTICIPANT_ROUTE,
        roles: [METHODIST_USER, COORDINATOR_USER, TRUSTEE_GENERAL_USER, TRUSTEE_LEGAL_USER, VOLUNTEER_MANAGER_USER, VOLUNTEER_USER],
        Component: Participants,
    },
    {
        path: PROFILE_ROUTE,
        roles: [METHODIST_USER, COORDINATOR_USER, TRUSTEE_GENERAL_USER, TRUSTEE_LEGAL_USER, VOLUNTEER_MANAGER_USER, VOLUNTEER_USER, GRADUATE_USER, MENTOR_USER],
        Component: Profile
    },
    {
        path: PLAN_ROUTE,
        roles: [METHODIST_USER],
        Component: Plan
    },
    {
        path: PLAN_ROUTE + '/:id',
        roles: [METHODIST_USER, COORDINATOR_USER],
        Component: PlanId
    },
    {
        path: OLYMPIAD_ROUTE,
        roles: [COORDINATOR_USER, METHODIST_USER, VOLUNTEER_MANAGER_USER, TRUSTEE_GENERAL_USER, TRUSTEE_LEGAL_USER, VOLUNTEER_USER],
        Component: Olympiads
    },
    {
        path: FINANCE_ROUTE,
        roles: [TRUSTEE_GENERAL_USER, TRUSTEE_LEGAL_USER],
        Component: Finance
    },
    {
        path: PROGRAMS_ROUTE,
        roles: [TRUSTEE_GENERAL_USER, TRUSTEE_LEGAL_USER, METHODIST_USER],
        Component: Programs
    },
    {
        path: MY_FEEDBACK_ROUTE,
        roles: [VOLUNTEER_USER],
        Component: MyFeedback
    },
    {
        path: ACTIVITY_ROUTE,
        roles: [VOLUNTEER_MANAGER_USER],
        Component: Activity
    },
    {
        path: FEEDBACKS_ROUTE,
        roles: [VOLUNTEER_MANAGER_USER],
        Component: Feedbacks
    },
    {
        path: QUESTIONNAIRES_ROUTE,
        roles: [VOLUNTEER_MANAGER_USER],
        Component: Questionnaires
    },
    {
        path: VOLUNTEERS_ROUTE,
        roles: [VOLUNTEER_MANAGER_USER],
        Component: Volunteers
    },
    {
        path: EVENTS_ROUTE,
        roles: [VOLUNTEER_USER],
        Component: Events
    },
    {
        path: BID_VOLUNTEERS_ROUTE + '/:id',
        roles: [VOLUNTEER_MANAGER_USER],
        Component: BidVolunteers
    },
    {
        path: MAILING_ROUTE,
        roles: [METHODIST_USER],
        Component: Mailing
    },
    {
        path: IMPORT_RESULT_ROUTE,
        roles: [METHODIST_USER],
        Component: ImportResult
    },
    {
        path: IMPORT_RESULT_ROUTE + '/:id',
        roles: [METHODIST_USER],
        Component: ImportResultId
    },
    {
        path: IMPORT_STATISTIC_ROUTE,
        roles: [METHODIST_USER],
        Component: ImportStatistic
    },
    {
        path: MY_RESUME_ROUTE,
        roles: [GRADUATE_USER],
        Component: MyResume
    },
    {
        path: NEWS_ROUTE,
        roles: [GRADUATE_USER],
        Component: News
    },
    {
        path: LIBRARIES_ROUTE,
        roles: [MENTOR_USER],
        Component: Libraries
    }
]

export {
    authRoutes, privateRoutes
};