import {
    COORDINATOR_USER, GRADUATE_USER, MENTOR_USER,
    METHODIST_USER,
    TRUSTEE_GENERAL_USER,
    TRUSTEE_LEGAL_USER,
    VOLUNTEER_MANAGER_USER, VOLUNTEER_USER
} from "../../consts/roles";

export const userDescription = (user, region) => {
    switch (user.user_type) {
        case COORDINATOR_USER:
            return `координатор-${region?.name ?? ''}`;
        case METHODIST_USER:
            return 'методист IQanat';
        case TRUSTEE_GENERAL_USER:
        case TRUSTEE_LEGAL_USER:
            const regionName = user.regions.map(region => ' ' + region.name);
            return `попечитель - ${regionName}`;
        case VOLUNTEER_MANAGER_USER:
            return `менеджер волонтерства`;
        case VOLUNTEER_USER:
            return `волонтер IQanat`;
        case GRADUATE_USER:
            return  "выпускник";
        case MENTOR_USER:
            return `ментор`;
        default:
            return null;
    }
};

