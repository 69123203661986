import DatePicker, {registerLocale} from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import '../components.scss';
import ru from "date-fns/locale/ru";
registerLocale("ru", ru);

const DatePickerWrapper = (props) => {
    const {
        text, name, value,
        method = () => {}, margin = [0, 0, 0, 0], disabled = false,
        showTimeSelect, showTimeSelectOnly, timeCaption,
        timeIntervals, customTimeInput, showTimeInput,
        shouldCloseOnSelect=true, withPortal=false,
        startDate
    } = props;

    const changeTimePickerHeaderName = () => document.querySelector(".react-datepicker-time__header") ? document.querySelector(".react-datepicker-time__header").innerText = "Время" : "Time";
    return (
        <div onClick={changeTimePickerHeaderName}>
            <DatePicker
                onChange={method}
                id={name}
                selected={value}
                value={value}
                name={name}
                disabled={disabled}
                placeholderText={text}
                dateFormat={text}
                className="input datepicker"
                showTimeSelect={showTimeSelect}
                showTimeSelectOnly={showTimeSelectOnly}
                timeIntervals={timeIntervals}
                timeCaption={timeCaption}
                customTimeInput={customTimeInput}
                showTimeInput={showTimeInput}
                shouldCloseOnSelect={shouldCloseOnSelect}
                withPortal={withPortal}
                autoComplete="off"
                // minDate={startDate ? startDate.getTime() : Date.now()}
                locale={ru}
                style={{
                    margin: `${margin[0]}px ${margin[1]}px ${margin[2]}px ${margin[3]}px`,
                }}/>
        </div>
    )
}

export default DatePickerWrapper;