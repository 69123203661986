import React from 'react';

const Divider = ({cls, color, width, height}) => {
    return (
        <hr
            className={`divider ${cls ?? ''}`}
            style={{
                width: width,
                border: `${height} solid ${color}`
            }}
        />
    );
};

export default Divider;