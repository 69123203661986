import {Fragment} from "react";

const TabPane = ({cls, children, isSelected}) => {
    return <Fragment>
        {
            isSelected
                ? <div
                    className={`tab__pane ${cls ? cls : ``}`}
                >
                    {
                        children
                    }
                </div>
                : null
        }
    </Fragment>
};

export default TabPane;