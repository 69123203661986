import "../../scss/modal.scss";
import {Icon} from "../index";
import {Fragment} from "react";

const Modal = ({cls, maxWidth, width, open, setOpen, children}) => {
    return (
        <Fragment>
            {
                open
                    ?  <div className={`modal ${cls ? cls: ""}`}>
                        <div className="modal-wrap" style={{maxWidth: maxWidth, width}}>
                            <button
                                className="modal__close"
                                onClick={() => {
                                    document.body.classList.remove("modal-open");
                                    setOpen(false);
                                }}
                            >
                                <Icon name="close" isColor={true} color="svg--primary"/>
                            </button>
                            {
                                children
                            }
                        </div>
                    </div>
                    : null
            }
        </Fragment>
    );
};

export default Modal;