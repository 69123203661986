import "../components.scss";
import {forwardRef} from "react";

const Card = forwardRef(({cls, width, height, children, method}, ref) => {
    return (
        <div className={`card ${cls ? cls : ''}`} style={{width, height}} ref={ref} onClick={method}>
            {
                children
            }
        </div>
    );
});

export default Card;