import icons from './icons.json';
import '../components.scss';

const Icon = ({name, isColor = false, color = 'svg--white', onClick, onMouseEnter, onMouseLeave, width, height}) => {
  return (
    <div
        className={`icon ${isColor ? color : ''}`}
        dangerouslySetInnerHTML={{__html: icons[name]}}
        onClick={onClick}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        style={{width, height}}
    >

    </div>
  )
}

export default Icon;