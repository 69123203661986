import axios from 'axios';
import { BASE_URL } from '../configs';
import {ACCESS_TOKEN, USER_TYPE} from "../consts/token";
import {AUTH_ROUTE} from "../routes/consts";

export const access_token = localStorage.getItem(ACCESS_TOKEN);

export const CancelToken = axios.CancelToken;
export let source = axios.CancelToken.source();

const options = {
    baseURL: BASE_URL,
    headers: { 'Authorization': `IQT ${access_token}` },
}

const Api = axios.create(options);

Api.interceptors.response.use(
    (response) => {
        return response.data;
    },
    (error) => {
        if (error.response) {
            if (error.response.status === 401) {
                localStorage.removeItem(ACCESS_TOKEN);
                localStorage.removeItem(USER_TYPE);
                window.location.href = AUTH_ROUTE;
            }
        }
        return Promise.reject(error);
});

export default Api;
