import {Link} from "react-router-dom";

const Tab = ({title, selected, setSelected, setCurrentPage, setFetching, setActivities, setLoading}) => {
    const selectTabHandler = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setCurrentPage && setCurrentPage(0);
        setFetching && setFetching(true);
        setActivities && setActivities([]);
        setLoading && setLoading(true);
        setSelected(title);
    }

    return (
        <li className={`tab ${selected ? 'active' : ''}`}>
            <Link
                to={"#"}
                className={"tab__link"}
                onClick={selectTabHandler}
            >
                {
                    title
                }
            </Link>
        </li>
    );
};

export default Tab;