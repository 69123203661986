import Api from '../../Api/Api';
import notify from "../../utils/notify/notify";

export const getUser = async () => {
  return Api.get('user/auth/info/')
}

export const changeUserValueCoordinator = async (value) => {
  try {
    const resp = await Api.patch('user/edit/role/coordinator/', value)
    if (resp) {
      notify("Изменения сохранены!", "success");
    }
    return resp
  } catch (error) {
    console.error('changeUserValue ERROR', error)
    notify(error.response.data.message, 'error');
  }
}

export const changeUserValueMethodist = async (value) => {
  try {
    const resp = await Api.patch('user/edit/role/methodist/', value)
    if (resp) {
      notify("Изменения сохранены!", "success");
    }
    return resp
  } catch (error) {
    console.error('changeUserValue ERROR', error)
    notify(error.response.data.message, 'error');
  }
}

export const changeUserValueTrusteeGeneral = async (value) => {
  try {
    const resp = await Api.patch('user/edit/role/trustee/general/', value)
    if (resp) {
      notify("Изменения сохранены!", "success");
    }
    return resp
  } catch (error) {
    console.error('changeUserValueTrusteeGeneral ERROR', error);
    notify(error.response.data.message, 'error');
  }
}

export const changeUserValueTrusteeLegal = async (value) => {
  try {
    const resp = await Api.patch('user/edit/role/trustee/legal/', value)
    if (resp) {
      notify("Изменения сохранены!", "success");
    }
    return resp
  } catch (error) {
    console.error('changeUserValueTrusteeLegal ERROR', error);
    notify(error.response.data.message, 'error');
  }
}

export const changeUserValueVolunteerManager = async (value) => {
  try {
    const resp = await Api.patch('/user/edit/role/volunteer-manager/', value)
    if (resp) {
      notify("Изменения сохранены!", "success");
    }
    return resp
  } catch (error) {
    console.error('changeUserValueVolunteerManager ERROR', error);
    notify(error.response.data.message, 'error');
  }
}

export const changeUserValueVolunteer = async (value) => {
  try {
    const resp = await Api.patch('/user/edit/role/volunteer/', value)
    if (resp) {
      notify("Изменения сохранены!", "success");
    }
    return resp
  } catch (error) {
    console.error('changeUserValueVolunteer ERROR', error);
    notify(error.response.data.message, 'error');
  }
}

export const changeUserValueGraduate = async (value) => {
  try {
    const resp = await Api.patch('/user/edit/role/graduate/', value);
    if (resp) {
      notify("Изменения сохранены!", "success");
    }
    return resp
  } catch (error) {
    console.error('changeUserValueGraduate ERROR', error);
    notify(JSON.stringify(error.response.data), 'error');
  }
}

export const changeUserValueMentor = async (value) => {
  try {
    const resp = await Api.patch('/user/edit/role/mentor/', value)
    if (resp) {
      notify("Изменения сохранены!", "success");
    }
    return resp
  } catch (error) {
    console.error('changeUserValueMentor ERROR', error);
    notify(error.response.data.message, 'error');
  }
}

export const changeUserValuePhoneNumber = async (value) => {
  try {
    const resp = await Api.put('/user/edit/phone/', value)
    if (resp) {
      notify("Изменения сохранены!", "success");
    }
    return resp
  } catch (error) {
    console.error('changeUserValuePhoneNumber ERROR', error);
    notify(error.response.data.message, 'error');
  }
}

