import {memo, useEffect, useState} from "react";
import {Link, withRouter} from "react-router-dom";
import {Icon} from '../../components';
import {
    OLYMPIAD_ROUTE,
    PARTICIPANT_ROUTE,
    PLAN_ROUTE,
    PROFILE_ROUTE,
    FINANCE_ROUTE,
    PROGRAMS_ROUTE,
    FEEDBACKS_ROUTE,
    ACTIVITY_ROUTE,
    MY_FEEDBACK_ROUTE,
    QUESTIONNAIRES_ROUTE,
    VOLUNTEERS_ROUTE,
    EVENTS_ROUTE,
    MAILING_ROUTE,
    IMPORT_RESULT_ROUTE,
    IMPORT_STATISTIC_ROUTE,
    MY_RESUME_ROUTE,
    NEWS_ROUTE,
    LIBRARIES_ROUTE
} from "../../routes/consts";
import {
    COORDINATOR_USER, GRADUATE_USER, MENTOR_USER,
    METHODIST_USER,
    TRUSTEE_GENERAL_USER,
    TRUSTEE_LEGAL_USER,
    VOLUNTEER_MANAGER_USER, VOLUNTEER_USER
} from "../../consts/roles";
import './Sidebar.scss';

const Sidebar = ({user, isSidebarHide, setSidebarHide, history, region, cls, setOpenBurger}) => {
    const [items, setItems] = useState([
        {icon: 'user', title: 'Мой профиль', url: PROFILE_ROUTE, isShow: true},
        {icon:  user.user_type === TRUSTEE_GENERAL_USER || user.user_type === TRUSTEE_LEGAL_USER ? 'olympiad-participant' : 'participants', title: user.user_type === TRUSTEE_GENERAL_USER ? 'Олимпиада IQanat' : 'Список участников', url: PARTICIPANT_ROUTE, isShow: user.user_type === COORDINATOR_USER || user.user_type === METHODIST_USER || user.user_type === TRUSTEE_GENERAL_USER || user.user_type === TRUSTEE_LEGAL_USER},
        {icon: 'plan', title: 'План', url: PLAN_ROUTE, isShow: user.user_type === METHODIST_USER},
        {icon: 'participants-methodist', title: 'Программы', url: PROGRAMS_ROUTE, isShow: user.user_type === TRUSTEE_GENERAL_USER || user.user_type === TRUSTEE_LEGAL_USER || user.user_type === METHODIST_USER},
        {icon: 'finance', title: 'Фин. отчетность', url: FINANCE_ROUTE, isShow: user.user_type === TRUSTEE_GENERAL_USER || user.user_type === TRUSTEE_LEGAL_USER},
        {icon: 'list', title: 'Cписок волонтеров', url: VOLUNTEERS_ROUTE, isShow: user.user_type === VOLUNTEER_MANAGER_USER},
        {icon: 'participants', title: 'Анкеты волонтеров', url: QUESTIONNAIRES_ROUTE, isShow: user.user_type === VOLUNTEER_MANAGER_USER},
        {icon: 'olympiad-volunteer-manager', title: 'Мероприятие', url: ACTIVITY_ROUTE, isShow: user.user_type === VOLUNTEER_MANAGER_USER},
        {icon: 'feedback', title: 'Отзывы', url: FEEDBACKS_ROUTE, isShow: user.user_type === VOLUNTEER_MANAGER_USER},
        {icon: 'olympiad-volunteer', title: 'Мероприятие', url: EVENTS_ROUTE, isShow: user.user_type === VOLUNTEER_USER},
        {icon: 'feedback', title: 'Мои отзывы', url: MY_FEEDBACK_ROUTE, isShow: user.user_type === VOLUNTEER_USER},
        {icon: 'mailing', title: 'Рассылка', url: MAILING_ROUTE, isShow: user.user_type === METHODIST_USER},
        {icon: 'olympiad', title: 'График олимпиады', url: OLYMPIAD_ROUTE, isShow: user.user_type === COORDINATOR_USER || user.user_type === METHODIST_USER || user.user_type === VOLUNTEER_MANAGER_USER || user.user_type === TRUSTEE_GENERAL_USER || user.user_type === TRUSTEE_LEGAL_USER || user.user_type === VOLUNTEER_USER},
        {icon: 'import', title: 'Импорт результатов', url: IMPORT_RESULT_ROUTE, isShow: user.user_type === METHODIST_USER},
        {icon: 'import-statistic', title: 'Статистика импорта', url: IMPORT_STATISTIC_ROUTE, isShow: user.user_type === METHODIST_USER},
        {icon: 'participants', title: 'Мое резюме', url: MY_RESUME_ROUTE, isShow: user.user_type === GRADUATE_USER},
        {icon: 'feedback', title: 'Новости', url: NEWS_ROUTE, isShow: user.user_type === GRADUATE_USER},
        {icon: 'olympiad', title: 'Библиотека', url: LIBRARIES_ROUTE, isShow: user.user_type === MENTOR_USER},
    ]);

    const defineActiveRoute = (pathname) => {
        const currentPathname = history.location.pathname.split("/")[1];
        const urlPathname = pathname.split("/")[1];
        if (`/${currentPathname}` === `/${urlPathname}`) {
            return true;
        } else {
            return false;
        }
    };

    const defineIcon = (item) => defineActiveRoute(item.url) && isSidebarHide ? item.icon + '-white' : item.icon;

    const checkedItem = (user_type) => {
        let newItem;
        if (user_type === COORDINATOR_USER) {
            newItem =  {icon: 'plan', title: 'План', url: PLAN_ROUTE + `/${region?.id}`, isShow: true}
        } else if(user_type === METHODIST_USER) {
            newItem =  {icon: 'olympiad', title: 'Олимпиада', url: OLYMPIAD_ROUTE, isShow: user?.user_type === COORDINATOR_USER}
        }
        const newItems = items.map(item => {
            if (item.icon === newItem.icon) {
                item = newItem
            }
            return item;
        });
        setItems(newItems);
    };

    useEffect(() => region && checkedItem(user?.user_type), [region]);
    return (
        <aside className={cls ? cls : `sidebar ${isSidebarHide ? "sidebar--hide" : "sidebar--show"}`}>
            <button
                className={`sidebar__arrow ${isSidebarHide ? "rotate" : "sidebar__arrow--auto"}`}
                onClick={() => setSidebarHide(!isSidebarHide)}
            >
                <img src="/assets/icons/double-arrow.svg" alt="double arrow"/>
            </button>
            {items.map(item => item.isShow &&
                <Link 
                    to={item.url} 
                    key={item.icon}
                    className={`sidebar__item ${defineActiveRoute(item.url) ? (isSidebarHide ? "sidebar__active--hide" : "sidebar__active") : ''}`}
                    onClick={() => setOpenBurger && setOpenBurger(false)}
                >
                    <Icon name={defineIcon(item)}/>
                    {!isSidebarHide && <p>{item.title}</p>}
                </Link>
            )}
        </aside>
    )
}

export default withRouter(memo(Sidebar));