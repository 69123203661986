import classNames from "classnames";
import "./Badge.scss";

export default function Badge({className, color="#E44C4C", count=0, limit=count}) {
    return (
        count > 0 && <div
            className={classNames("badge", className)}
            style={{backgroundColor: color}}
        >
            <h6 className={"badge__count"}>
                {count > limit ? `${limit}+` : count}
            </h6>
        </div>
    )
}