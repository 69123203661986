const AUTH_ROUTE = "/auth";
const FORGOT_PASSWORD_ROUTE = "/auth/forgot-password";
const AUTH_COORDINATOR_ROUTE = "/auth/coordinator";
const AUTH_METHODIST_ROUTE = "/auth/methodist";
const AUTH_VOLUNTEER_MANAGER_ROUTE = "/auth/volunteer-manager";
const AUTH_TRUSTEE_GENERAL_ROUTE = "/auth/trustee-general";
const AUTH_TRUSTEE_LEGAL_ROUTE = "/auth/trustee-legal";
const AUTH_VOLUNTEER_ROUTE = "/auth/volunteer";
const AUTH_GRADUATE_ROUTE = "/auth/graduate";
const AUTH_MENTOR_ROUTE = "/auth/mentor";
const NOTFOUND_ROUTE = "/404";
const PARTICIPANT_ROUTE = "/participant";
const PROFILE_ROUTE = "/";
const PLAN_ROUTE = "/plan";
const OLYMPIAD_ROUTE = "/olympiad";
const FINANCE_ROUTE = "/finance";
const PROGRAMS_ROUTE = "/programs";
const FEEDBACKS_ROUTE = "/feedbacks";
const MY_FEEDBACK_ROUTE = "/my-feedback";
const ACTIVITY_ROUTE = "/activity";
const EVENTS_ROUTE = "/events";
const ACCESS_TOKEN_ROUTE = "/access_token";
const QUESTIONNAIRES_ROUTE = "/questionnaires";
const VOLUNTEERS_ROUTE = "/volunteers";
const BID_VOLUNTEERS_ROUTE = "/activity/bid/volunteers";
const MAILING_ROUTE = "/mailing";
const IMPORT_RESULT_ROUTE = "/import-result";
const IMPORT_STATISTIC_ROUTE = "/import-statistic";
const NEWS_ROUTE = "/news";
const MY_RESUME_ROUTE = "/my-resume";
const LIBRARIES_ROUTE = "/libraries";

export {
    AUTH_ROUTE, PLAN_ROUTE, PARTICIPANT_ROUTE,
    PROFILE_ROUTE, OLYMPIAD_ROUTE, NOTFOUND_ROUTE,
    AUTH_METHODIST_ROUTE, AUTH_COORDINATOR_ROUTE,
    FORGOT_PASSWORD_ROUTE, FINANCE_ROUTE, PROGRAMS_ROUTE,
    FEEDBACKS_ROUTE, ACTIVITY_ROUTE, MY_FEEDBACK_ROUTE,
    ACCESS_TOKEN_ROUTE, QUESTIONNAIRES_ROUTE, VOLUNTEERS_ROUTE,
    EVENTS_ROUTE, AUTH_VOLUNTEER_MANAGER_ROUTE, AUTH_VOLUNTEER_ROUTE,
    AUTH_TRUSTEE_GENERAL_ROUTE, AUTH_TRUSTEE_LEGAL_ROUTE, BID_VOLUNTEERS_ROUTE,
    MAILING_ROUTE, IMPORT_RESULT_ROUTE, IMPORT_STATISTIC_ROUTE, AUTH_GRADUATE_ROUTE,
    NEWS_ROUTE, MY_RESUME_ROUTE, AUTH_MENTOR_ROUTE, LIBRARIES_ROUTE
};

