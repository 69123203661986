import {Fragment, useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import {PLAN_ROUTE} from "../../routes/consts";
import {CheckBox, Progress, ListInput, Icon} from "../index";
import '../components.scss';
import Empty from "../Empty/Empty";

const List = ({items = [], params = [], count, width = '1043px', method = () => {}, offset, selectedId, setSelectedId, fixed, selectedError}) => {
    const [paramItems, setParamItems] = useState(null);
    const [currentInput, setCurrentInput] = useState(null);
    const [currentId, setCurrentId] = useState(null);
    const [currentItemTitle, setCurrentItemTitle] = useState(null);

    useEffect(() => {
        const arr = []
        params.map(param => {
            const titles = []
            // if (!widthWindow) {
                titles.push({title: param.title})
                // }
                items.map((item, index) => {
                if (param.key === 'number') {
                    return titles.push({title: offset ? offset + index + 1 : index + 1, id: item.id});
                } else if (param.key === "is_passed") {
                    return titles.push({title: item.is_passed ? "Прошел" : "Не прошел", id: item.id ?? index + 1, key: param.key})
                } else if (param.key === "self_pay") {
                    return titles.push({title: item.self_pay ? "Да" : "Нет", id: item.id});
                } else if (param.key === "work_area") {
                    return titles.push({title: item.work_area === 'Major event' ? "Республиканский" : "Сельский", id: item.id});
                } else if (param.key === 'location_info') {
                    return titles.push({title: item.location_info ? item.location_info : 'Поле не заполнено', id: item.id})
                } else if (param.key === 'begin_date') {
                    return titles.push({title: item.begin_date ? item.begin_date : 'Нет даты', id: item.id})
                } else if (param.key === 'end_date') {
                    return titles.push({title: item.end_date ? item.end_date : 'Нет даты', id: item.id})
                } else if (param.key === 'begin_time') {
                    return titles.push({title: item.begin_time ? item.begin_time : 'Нет времени', id: item.id})
                } else if (param.key === 'end_time') {
                    return titles.push({title: item.end_time ? item.end_time : 'Нет времени', id: item.id})
                } else if (param.key === "action") {
                    return titles.push({title: item.action.name, id: item.action.region_id, is_active: item.action.is_active})
                } else if (param.key === "approve") {
                    return titles.push({title: item.id, id: item.id})
                } else if (param.key === 'members_amount') {
                    return titles.push({title: item.members_amount, id: item.id, success: item.success})
                } else if (param.key === 'success') {
                    return titles.push({title: item.success, id: item.id})
                } else if (item[param.key]) {
                    return titles.push({title: item[param.key], id: item.id ?? index + 1})
                } else {
                    return titles.push({title: null, id: item.id ?? index + 1})
                }
            })
            const data = {
                title: param.key,
                items: titles,
                width: param.width,
                isDontTuch: param.isDontTuch
            }
            return arr.push(data)
        })
        setParamItems(arr)
    }, [items, offset, params])

    const changeItem = (currentItem, arg) => {
        setCurrentInput(currentItem.title)
        setCurrentId(currentItem.id)
        setCurrentItemTitle(arg)
    }

    const changeCheckbox = (arr, id) => {
        if (arr.includes(id)) {
            setSelectedId(arr.filter(el => el !== id))
        } else {
            setSelectedId([...arr, id])
        }
    }
    return (
        <Fragment>
            <p className="list__label">Результаты по вашему запросу: {count}</p>
            <div className="list-wrapper">
                <div className="list" style={{width: `${width}px`}}>
                    <div className="list-content">
                        {paramItems && items.length > 0 ?
                            paramItems.map(item => {
                                return (
                                    <div
                                        key={item.title}
                                        className={`list__column 
                                            ${fixed === 'name' && item.title === 'name' ? 'list__fix-column_second list__shadow list_l-40-30' : ''}
                                            ${fixed === 'full_name' && item.title === 'full_name' ? 'list__fix-column_second list__shadow' : ''}
                                            ${fixed === 'district' && item.title === 'district' ? 'list__fix-column_second list__shadow ' : ''}
                                            ${fixed === 'region' && item.title === 'region' ? 'list__fix-column_second list__shadow' : ''}
                                            ${fixed === 'approve' && item.title === 'name' ? 'list__fix-column_second list__shadow list_l-80-60' : ''}
                                            ${fixed === 'approve' && item.title === 'number' ? 'list_l-40-30' : ''}
                                            ${item.title === 'number' ? 'list__fix-column list__number' : ''}
                                            ${item.title === 'approve' ? 'list__fix-column list__number list__checkbox_first' : ''}
                                        `}
                                    >
                                        {item.items.map((i, index) => {
                                            return (
                                                item.title === 'success' ?
                                                    <div key={index} className="list__wrap">
                                                        <div className={`list__row list__row--success`}>
                                                            {
                                                                typeof i.title === "number"
                                                                    ? <Progress percent={i.title}/>
                                                                    : i.title
                                                            }
                                                        </div>
                                                    </div> :
                                                    <div key={index}
                                                        className={`list__wrap ${selectedId?.includes(i.id) ? 'chose' : ''}`}
                                                    >
                                                        {item.isDontTuch ?
                                                        // это цифры (номера строк)
                                                            <div className={`list__row 
                                                            ${item.title === 'approve' ? 'list_pl-0' : '' } 
                                                            ${window.innerWidth < 500 ? 'list-max_w' : '' }`} >
                                                                {item.title === "action"
                                                                    ? <Link to={PLAN_ROUTE + `/${i.id}`}>{i.title ?? null}</Link>
                                                                    : item.title === "approve" ?
                                                                    <CheckBox cls={"list__checkbox"}
                                                                                value={selectedId}
                                                                                name={i.title}
                                                                                onClick={() => changeCheckbox(selectedId, i.title)}
                                                                    />
                                                                    : <p className={`${item.title === "members_amount" ? (i.success >= 100 ? 'success' : (i.success <= 30 ? 'failure' : 'warning')) : ''}`}>
                                                                        {i.title}
                                                                    </p>
                                                                }
                                                            </div>
                                                            : <div className={`list__row ${item.title === 'location_info' ? 'list__row--location' : ''}`} style={{cursor: 'pointer'}}
                                                                onClick={() => changeItem(i, item.title)}
                                                            >
                                                                <p
                                                                    className={`${item.title === 'name' && 'list__data_text'} ${selectedError === "true" && index !== 0 ? "failure" : ""}`}
                                                                >
                                                                    {i.title}
                                                                </p>
                                                            </div>
                                                        }
                                                        {(currentInput === i.title && currentId === i.id && currentItemTitle === item.title) && index !== 0 &&
                                                        <ListInput
                                                            defaultValue={{title: i.title, id: i.id, key: item.title}}
                                                            data={item} changeItem={changeItem} onSubmit={method}
                                                            currentInput={currentInput}
                                                            setCurrentInput={setCurrentInput}/>
                                                        }
                                                    </div>
                                            )
                                        })}
                                    </div>
                                )
                            }) : <Empty cls={"list-empty"} title={"По вашем запросу данных не найдено"} icon={<Icon name="empty"/>} width={"100%"} height={"200px"}/>
                        }
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default List;