import {parseFileString} from "./parseFileString";
import {DOCX_EXTENSION, PDF_EXTENSION, WORD_EXTENSION, XLS_EXTENSION, XLSX_EXTENSION} from "../../consts/extensions";

export const getExtension = (url) => {
    const extension = parseFileString(url);
    switch (extension) {
        case PDF_EXTENSION:
            return 'pdf-file';
        case WORD_EXTENSION:
            return 'word-file';
        case DOCX_EXTENSION:
            return 'word-file';
        case XLSX_EXTENSION:
            return 'excel-file';
        case XLS_EXTENSION:
            return 'excel-file';
        default:
            return 'default-file';
    }
};