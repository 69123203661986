const COORDINATOR_USER = "coordinator_user";
const METHODIST_USER = "methodist_user";
const STUDENT_USER = "student_user";
const VOLUNTEER_USER = "volunteer_user";
const TEACHER_USER = "teacher_user";
const MENTOR_USER = "mentor_user";
const TRUSTEE_GENERAL_USER = "trustee_general_user";
const TRUSTEE_LEGAL_USER = "trustee_legal_user";
const VOLUNTEER_MANAGER_USER = "volunteer_manager_user";
const GRADUATE_USER = "graduate_user";

export {
    COORDINATOR_USER, METHODIST_USER, MENTOR_USER,
    STUDENT_USER, VOLUNTEER_USER, TEACHER_USER,
    TRUSTEE_GENERAL_USER, TRUSTEE_LEGAL_USER,
    VOLUNTEER_MANAGER_USER, GRADUATE_USER
};