export default function windowSize(callback) {
    function reportWindowSize() {
        if (window.innerWidth > 768) {
            callback();
        } 
    }

    window.addEventListener('resize', reportWindowSize);
    return () => {
        window.removeEventListener('resize', reportWindowSize);
    };
};