import {Fragment, useCallback, useEffect, useMemo, useState} from "react";
import {useLocation} from "react-router-dom";
import {ToastContainer} from "react-toastify";
import Header from "./Layout/Header/Header";
import Sidebar from "./Layout/Sidebar/Sidebar";
import {AppLoader, AppRouter, Container} from "./components";
import {getUser} from "./pages/Profile/Profile.service";
import {getOlympiadCoordinators, getRegionId} from "./pages/Participants/Participants.service";
import {access_token} from "./Api/Api";
import {privateRoutes} from "./routes/routes";
import {AUTH_ROUTE, PROFILE_ROUTE} from "./routes/consts";
import {ACCESS_TOKEN, USER_TYPE} from "./consts/token";
import {COORDINATOR_USER, METHODIST_USER} from "./consts/roles";
import './App.scss';
import 'react-toastify/dist/ReactToastify.css';

const App = () => {
    const [user, setUser] = useState(null);
    const [isSidebarHide, setSidebarHide] = useState(false);
    const [olympiadCoordinator, setOlympiadCoordinator] = useState(null);
    const [olympiadId, setOlympiadId] = useState(null);
    const [region, setRegion] = useState(null);

    const isLogged = useMemo(() => !!access_token, []);
    const location = useLocation();
    const isFindLocationPathname = isLogged && privateRoutes.find(({path}) => path.split('/')[1] === location.pathname.split('/')[1]);

    const logout = () => {
        localStorage.removeItem(ACCESS_TOKEN);
        localStorage.removeItem(USER_TYPE);
        localStorage.removeItem('selectedOlympiadParticipantsTags');
        localStorage.removeItem('selectedCertificateTemplate');
        localStorage.removeItem('selectedOlympiadLast');
        window.location.href = AUTH_ROUTE;
    };

    const getUserHandler = async () => {
        try {
            const res = await getUser();
            const newUser = res;
            newUser.full_name = `${res.surname} ${res.name} ${res.middlename ?? ''}`;
            localStorage.setItem(USER_TYPE, res.user_type);
            setUser(newUser);
        } catch (error) {
            localStorage.removeItem(ACCESS_TOKEN);
            localStorage.removeItem(USER_TYPE);
            window.location.href = AUTH_ROUTE;
        }
    };

    const getOlympiadCoordinatorsHandler = useCallback(async () => {
        const res = await getOlympiadCoordinators(user.id);
        if (res.length > 0) {
            setOlympiadCoordinator(res[0].region);
            setOlympiadId(res[0].olympiad);
        }
    }, [user?.id])

    const getRegionIdHandler = useCallback(async () => {
        const res = olympiadCoordinator && await getRegionId(olympiadCoordinator);
        setRegion(res?.result);
    }, [olympiadCoordinator]);

    useEffect(() => {
        const arr = location.pathname.split('/');
        if (arr.includes(ACCESS_TOKEN)) {
            const token = arr[2];
            localStorage.setItem(ACCESS_TOKEN, token);
            window.location.href = PROFILE_ROUTE;
        }
    }, [location.pathname]);

    useEffect(() => isLogged && getUserHandler(), [isLogged]);

    useEffect(() => (isLogged && (user?.user_type === COORDINATOR_USER || user?.user_type === METHODIST_USER)) && getOlympiadCoordinatorsHandler(), [isLogged, user?.user_type, getOlympiadCoordinatorsHandler]);

    useEffect(() => (isLogged && user?.user_type === COORDINATOR_USER) && getRegionIdHandler(), [isLogged, user?.user_type, getRegionIdHandler]);

    if (!user && isLogged) {
        return <AppLoader />;
    }
    return (
        <Fragment>
            <div className="app">
                <ToastContainer/>
                <Header
                    isSidebarHide={isSidebarHide} 
                    setSidebarHide={setSidebarHide}
                    user={isFindLocationPathname && user}
                    region={isLogged ? region : null}
                    logout={logout}
                />
                <Container>
                    <div className="app-wrap">
                        {
                            isFindLocationPathname && <Sidebar user={user} isSidebarHide={isSidebarHide} setSidebarHide={setSidebarHide} region={region}/>
                        }
                        <AppRouter isLogged={isLogged} user={user} region={region} olympiadId={olympiadId} olympiadCoordinator={olympiadCoordinator}/>
                    </div>
                </Container>
            </div>
        </Fragment>
    );
}

export default App;
