import {Tab} from "../index";
import "../components.scss";

const Tabs = ({cls, tabs, selected, setSelected, style, setCurrentPage, setFetching, setActivities, setLoading}) => {
    return (
        <div className={`tabs ${cls ? cls : ``}`} style={style}>
            <ul className="tabs__list">
                {
                    tabs.map((tab) =>
                        {
                            const active = (tab.toLowerCase() === selected.toLowerCase() ? "active" : "");
                            return (
                                <Tab
                                    key={`${Math.random()}${tab}`}
                                    title={tab}
                                    selected={active}
                                    setSelected={setSelected}
                                    setCurrentPage={setCurrentPage}
                                    setFetching={setFetching}
                                    setActivities={setActivities}
                                    setLoading={setLoading}
                                />
                            )
                        }
                    )
                }
            </ul>
        </div>
    );
};

export default Tabs;