import {useCallback, useState} from "react";
import {useDropzone} from "react-dropzone";
import Icon from "../Icon/Icon";
import {getExtension} from "../../utils/file/getExtension";
import "./Upload.scss";

const Upload = (props) => {
    const {title, maxFiles, multiple = true, isDownload = false, onChange, initialFiles=[]} = props;

    const onDrop = useCallback(acceptedFiles => {
        const filesCount = files.length + acceptedFiles.length;
        if (multiple) {
            if (filesCount <= maxFiles) {
                setFiles(prev => [...prev, ...acceptedFiles]);
                onChange([...files, ...acceptedFiles]);
            } else {
                return acceptedFiles;
            }
        } else {
            setFiles(acceptedFiles);
            onChange(acceptedFiles);
        }
    }, [onChange, maxFiles, multiple]);

    const {acceptedFiles, getRootProps, getInputProps} = useDropzone({
        onDrop,
        multiple,
        maxFiles,
        accept: {
            "application/pdf": [".pdf"]
        }
    });

    const [files, setFiles] = useState(initialFiles.length > 0 ? initialFiles : acceptedFiles);

    const removeFile = (file) => {
        const newFiles = [...files];
        newFiles.splice(newFiles.indexOf(file), 1);
        setFiles(newFiles);
        onChange(newFiles);
    };

    const downloadFile = (file) => {
        const blob = new Blob([file], { encoding: 'UTF-8' });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = file.name;

        link.click()

        return () => {
            link.remove()
        }
    };
    return (
        <div className={"upload"}>
            <div className={"upload__input"} {...getRootProps()}>
                <Icon name={"upload-folder"}/>
                <p className={"upload__title"}>{title}</p>
                <p className={"upload__subTitle"}><strong>Нажмите</strong> или перетащите файл</p>
                <p className={"upload__desc"}>(Вы можете загружать одновременно не более 1 файла)</p>
                <input {...getInputProps()} />
            </div>
            {files.length > 0 && <ul className={"upload__files"}>
                {files.map((file, fileId) => (
                    <li key={fileId} className={"upload__file"}>
                        <div className={"upload__doc"}>
                            <Icon name={getExtension(file.name)}/>
                            <p>{file.name.split(".")[0]}</p>
                        </div>
                        <div className={"upload__action"}>
                            {isDownload && (
                                file.serial
                                    ? <a href={file.path} target={"_blank"} rel={"noreferrer noopener"} download>
                                        <Icon name={"download-arrow"}/>
                                    </a>
                                    : <div onClick={() => downloadFile(file)}>
                                        <Icon name={"download-arrow"}/>
                                    </div>
                            )}
                            <Icon name={"delete"} onClick={() => removeFile(file)}/>
                        </div>
                    </li>
                ))}
            </ul>}
        </div>
    )
};

export default Upload;