import {userDescription} from "../../../utils/user/userDescription";
import {Link} from "react-router-dom";
import {PROFILE_ROUTE} from "../../../routes/consts";
import {BurgerMenu} from "../../../components";

export default function HeaderProfile({openBurger, setOpenBurger, user, region, isSidebarHide, setSidebarHide, logout}) {
    return (
        <div className="header__auth">
            <div className="header__profile">
                <div className="profile__info">
                    <h6 className="profile__name">{user.full_name}</h6>
                    <p className="profile__role">{userDescription(user, region)}</p>
                </div>
                <Link to={PROFILE_ROUTE}>
                    <img className="profile__avatar" src={user.avatar ?? "/assets/icons/avatar.svg"} alt="avatar"/>
                </Link>
            </div>
            <button className="header__logout" onClick={logout}>
                <img className="logout__img" src="/assets/icons/exit.svg" alt="Logout"/>
            </button>
            <div
                onClick={() => {
                    setSidebarHide(false);
                    setOpenBurger(openBurger => !openBurger)
                }}
                className="header__burger"
            >
                <img className="burger__img" src="/assets/icons/burger.svg" alt="Menu"/>
            </div>
            <BurgerMenu
                openBurger={openBurger}
                setOpenBurger={setOpenBurger}
                user={user}
                region={region}
                isSidebarHide={isSidebarHide}
                setSidebarHide={setSidebarHide}
                logout={logout}
            />
        </div>
    );
};