import '../components.scss'

const Progress = ({percent, height, fz, currentCount, maxCount, color}) => {

    const maxPercent = 100;

    return (
        <section className="progress">
            <div className="progress-wrap" style={{height: height}}>
                <div className="progress-percent" style={{width: `${percent > maxPercent ? maxPercent : percent}%`,background: color}}></div>
                {currentCount && <div className="progress__data">{currentCount}/{maxCount}</div>}
            </div>
            <p style={{fontSize: fz}}>{percent > maxPercent ? maxPercent : percent}%</p>
        </section>
    )
}

export default Progress