import "../components.scss";

const Empty = ({cls, title, content, icon, width, height}) => {
    return (
        <div className={`empty ${cls ?? ""}`} style={{width, height}}>
            <div className="empty__title">
                <h3>{title}</h3>
            </div>
            <div className="empty__content">
                {
                    content
                }
            </div>
            <div className="empty__icon">
                {
                    icon
                }
            </div>
        </div>
    );
};

export default Empty;