import {Suspense} from "react";
import {Redirect, Route, Switch} from "react-router-dom";
import {authRoutes, privateRoutes} from "../../routes/routes";
import {ACCESS_TOKEN, USER_TYPE} from "../../consts/token";
import {ACCESS_TOKEN_ROUTE, NOTFOUND_ROUTE} from "../../routes/consts";
import "./AppRouter.scss";

const AppRouter = ({isLogged, user, region, olympiadId, olympiadCoordinator}) => {
    return (
        <Switch>
            {
                isLogged && (
                    privateRoutes.map(({path, roles, Component}) => (
                        roles.includes(localStorage.getItem(USER_TYPE)) && (
                            <Route
                                key={path}
                                path={path}
                                render={() => <Suspense fallback={"loading..."}>
                                    <Component
                                        user={user}
                                        region={region}
                                        olympiadId={olympiadId}
                                        olympiadCoordinator={olympiadCoordinator}
                                    />
                                </Suspense>}
                                exact
                            />
                        )
                    ))
                )
            }
            {
                authRoutes.map(({path, Component}) => (
                    <Route
                        key={path}
                        path={path}
                        render={() => <Suspense fallback={"loading..."}>
                            <Component isLogged={isLogged}/>
                        </Suspense>}
                        exact
                    />
                ))
            }
            <Route exact path={ACCESS_TOKEN_ROUTE + `/:${ACCESS_TOKEN}`}/>
            <Redirect to={NOTFOUND_ROUTE}/>
        </Switch>
    );
};

export default AppRouter;