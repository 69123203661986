import {useState} from "react";
import {Icon, Input} from "../index";
import "../components.scss";

const Rating = ({count, size, rate, setRate, disabled, readOnly}) => {
    const [hoverValue, setHoverValue] = useState(rate || null);

    return (
        <div className="rating">
            {
                [...Array(count ?? 5)].map((star, index) => {
                    const ratingValue = index + 1;
                    return (
                        <label
                            key={index}
                            className={"rating__label"}
                        >
                            <Input
                                type="radio"
                                name="rating"
                                value={ratingValue}
                                disabled={disabled}
                                readOnly={readOnly}
                                method={() => setRate(ratingValue)}
                            />
                            <Icon
                                name="star"
                                width={size}
                                height={size}
                                isColor={true}
                                color={(hoverValue || rate) && (hoverValue || rate) > index ? "svg--gold" : "svg--default"}
                                onMouseEnter={() => !readOnly && setHoverValue(ratingValue)}
                                onMouseLeave={() => !readOnly && setHoverValue(null)}
                            />
                        </label>
                    );
                })
            }
        </div>
    );
};

export default Rating;