import {forwardRef} from "react";
import classNames from "classnames";
import "./Table.scss";

export default forwardRef(function Table({className, columns, records, ...otherProps}, ref) {
    return (
        <div
            className={classNames("table", className)}
            ref={ref}
            {...otherProps}
        >
            {
                columns.map((column, columnIndex) => (
                    <div key={columnIndex} className={classNames("table__column", {"table__fixed" : columnIndex === 0})} style={{width: `${column.width}%`}}>
                        <div className={"table__cell"}>{column.name}</div>
                        {
                            records.map((record, recordIndex) => (
                                <div key={recordIndex} className={"table__cell"}>
                                    {Object.values(record)[columnIndex]}
                                </div>
                            ))
                        }
                    </div>
                ))
            }
        </div>
    );
});