import Api, {CancelToken} from '../../Api/Api';

export let cancel = null;

export const getParticipants = async (limit=10, offset, search, gender='male', district_id, school_id, class_number, region_id, round) => {
    try {
        const res = await Api.get('olympiad/participants/', {
            params: {
                limit,
                offset,
                search: search || null,
                gender,
                district_id,
                school_id,
                class_number,
                region_id,
                round
            }
        });
        return res;
    } catch (error) {
        console.error('getParticipants ERROR', error);
    }
};

export const patchParticipateValue = async (value, id) => {
    try {
        const res = await Api.patch(`/olympiad/participants/${id}/`, value);
        return res;
    } catch (error) {
        console.error('patchParticipateValue ERROR', error);
    }
};

export const getExcelFile = async (limit, offset, region_id, district_id, gender, olympiad_id, coordinator_user_id, search, school_id) => {
    try {
        const res = await Api.get('olympiad/participants/export/excel/', {
            responseType: "blob",
            params: {
                limit,
                offset,
                region_id,
                district_id,
                gender,
                olympiad_id,
                coordinator_user_id,
                search: search || null,
                school_id
            }
        });
        return res
    } catch (error) {
        console.error('getExcelFile ERROR', error);
    }
};

export const getExcelSample = async () => {
    try {
        const res = await Api.get('olympiad/participants/import/excel/template/', {
            responseType: "blob",
        });
        return res;
    } catch (error) {
        console.error('getExcelSample ERROR', error);
    }
};

export const importExcel = async (file, options) => {
    const res = await Api.post('olympiad/participants/import/excel/', file, {
        ...options,
        cancelToken: new CancelToken(function executor(c) {
            cancel = c;
        })
    });
    return res;
};

export const getOlympiadCoordinators = async (coordinator_user_id) => {
    try {
        const res = await Api.get('olympiad/coordinators/', {
            params: {
                coordinator_user_id
            }
        })
        return res;
    } catch (error) {
        console.error('getOlympiadCoordinators ERROR', error);
    }
};

export const getRegionId = async (id) => {
    try {
        const res = await Api.get(`core/regions/${id}/`);
        return res;
    } catch (error) {
        console.error('getRegionId ERROR', error);
    }
};

export const getRegions = async () => {
    try {
        const res = await Api.get('core/regions/');
        return res.result;
    } catch (error) {
        console.error('getRegions ERROR', error);
    }
};

export const getDistricts = async (region_id) => {
    try {
        const res = await Api.get('core/districts/', {
            params: {
                region_id
            }
        });
        return res.result;
    } catch (error) {
        console.error('getDistricts ERROR', error);
    }
};

export const getSchools = async (region_id, district_id) => {
    try {
        const res = await Api.get('core/schools/', {
            params: {
                region_id,
                district_id
            }
        });
        return res.result;
    } catch (error) {
        console.error('getSchools ERROR', error);
    }
};

export const getParticipantBatches = async () => {
    try {
        const {results} = await Api.get("/batches/");
        return results;
    } catch (error) {
        console.error('getParticipantBatches ERROR', error);
    }
};