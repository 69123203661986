import {useCallback, useEffect, useRef, useState} from "react"
import '../../components.scss'
import DatePickerWrapper from "../../DatePickerWrapper/DatePickerWrapper"

const ListInput = ({defaultValue, changeItem, data, translate, currentInput, setCurrentInput, onSubmit}) => {
    let date = {};
    let time = {};
    let dateTime = {};
    const listInputRef = useRef(null)
    const titles = ["email"];
    const isDatePickers = ["begin_date", "end_date"];
    const isTimePickers = ["begin_time", "end_time"];

    if (defaultValue.title === 'Нет даты') {
        const currentDate = `${new Date().getDate()}.${new Date().getMonth()+1}.${new Date().getFullYear().toString().slice(2, 4)}`
        defaultValue.title = currentDate
    }

    if (defaultValue.title === 'Нет времени') {
        const currentTime = `${new Date().getHours()}:${new Date().getMinutes()}`
        defaultValue.title = currentTime
    }

    if (defaultValue.title === 'Поля не заполнено') {
        defaultValue.title = '';
    }

    if (isDatePickers.includes(data.title)) {
        const format = defaultValue.title.split('.')
        date.day = format[0];
        date.month = format[1];
        date.year = `20${format[2]}`;
    }

    if (data.title === "deadline") {
        const format = defaultValue.title.split('.')
        dateTime.day = format[0];
        dateTime.month = format[1];
        dateTime.year = `20${format[2]}`;
    }

    if (isTimePickers.includes(data.title)) {
        const format = defaultValue.title.split(":");
        time.hours = format[0];
        time.minutes = format[1];
    }

    const [input, setInput] = useState(isDatePickers.includes(data.title) ? new Date(date.year, date.month - 1, date.day) : (data.title === "deadline" ? new Date(dateTime.year, dateTime.month - 1, dateTime.day) : isTimePickers.includes(data.title) ? new Date(0, 0, 0, time.hours, time.minutes) : defaultValue.title ? defaultValue.title : ''))

    const save = (value, id) => {
        let shortValue = `${new Date(value).getFullYear()}-${new Date(value).getMonth() + 1}-${new Date(value).getDate()}`
        let timeValue = `${new Date(value).getHours()}:${new Date(value).getMinutes()}`;
        let dateField;
        let timeField;
        let dateTimeField;
        if (data.title === "deadline") {
            dateTimeField = `${shortValue}T00:00:00.000Z`;
        } else if (isDatePickers.includes(data.title)) {
            dateField = shortValue;
        } else if (isTimePickers.includes(data.title)) {
            timeField = timeValue;
        } else {
            dateField = shortValue;
        }
        onSubmit(isDatePickers.includes(data.title) ? dateField : (data.title === "deadline" ? dateTimeField : isTimePickers.includes(data.title) ? timeField : value), id, defaultValue.key)
        changeItem({title: null, id: null}, null)
    }

    const checkIfClickedOutside = useCallback(e => {
        if (currentInput && listInputRef.current && !listInputRef.current.contains(e.target)) {
            setCurrentInput(null)
        }
    },[currentInput, setCurrentInput])

    useEffect(() => {
        document.addEventListener("mousedown", checkIfClickedOutside)
        return () => {
            document.removeEventListener("mousedown", checkIfClickedOutside)
        }
    }, [checkIfClickedOutside])

    return (
        <section className="list-input" style={{
            width: `${titles.includes(data.title) ? data.width - 5 : data.width + 1}%`,
            transform: translate
        }} ref={listInputRef}>
            {
                data.title === "deadline" ?
                    <DatePickerWrapper name='date' text="dd.MM.yy" method={setInput} value={input}/> :
                    isDatePickers.includes(data.title)
                        ? <DatePickerWrapper name='date' text="dd.MM.yy" method={setInput} value={input}/>
                        :
                        (
                            isTimePickers.includes(data.title)
                                ? <DatePickerWrapper name="time" text="HH:mm" method={setInput} value={input}
                                    showTimeSelect={true} showTimeSelectOnly={true} timeIntervals={15}/>
                                : <input type="text" value={input} onChange={(e) => setInput(e.target.value)}/>
                        )
            }
            <div className="list-input-btns">
                <button onClick={() => save(input, defaultValue.id)}><img src="/assets/icons/check.svg" alt=""/></button>
                <button onClick={() => changeItem({title: null, id: null}, null)}><img src="/assets/icons/close.svg" alt=""/></button>
            </div>
        </section>
    )
}

export default ListInput;