import "../components.scss";

const LoaderBtn = ({loaderCls}) => {
    return (
        <div className={`loader-btn ${loaderCls ?? ''}`}>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    );
};

export default LoaderBtn;