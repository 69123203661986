import Api from "../../Api/Api";

export const getOlympiadRegistrations = async (region_id) => {
    try {
        const res = await Api.get('/olympiad/registrations/', {
            params: {
                region_id
            }
        });
        return res;
    } catch (error) {
        console.error("GetOlympiadRegistrations ERROR!", error);
    }
};

export const getOlympiadStatisticsRegistrationByRegion = async (limit, offset) => {
    try {
        const res = await Api.get('/olympiad/statistics/registrations-by-region/', {
            params: {
                limit,
                offset
            }
        });
        return res;
    } catch (error) {
        console.error("getOlympiadStatisticsRegistrationByRegion ERROR!", error);
    }
};

export const getStatisticsRegistrationsByDistrict = async (limit, offset, region_id) => {
    try {
        const res = await Api.get('/olympiad/statistics/registrations-by-district/', {
            params: {
                limit,
                offset,
                region_id
            }
        });
        return res;
    } catch (error) {
        console.error("getOlympiadStatisticsRegistrationByRegion ERROR!", error);
    }
};

export const patchPlanValue = async (value, id) => {
    try {
        const res = await Api.patch(`/olympiad/registrations/${id}/`, value)
        return res;
    } catch (error) {
        console.error('patchPlanValue ERROR', error);
    }
};

export const getOlympiadStatisticsRegistrationSummary = async (region_id) => {
    try {
        const res = await Api.get('/olympiad/statistics/registrations-summary/', {
            params: {
                region_id
            }
        });
        return res;
    } catch (error) {
        console.error('getOlympiadStatisticsRegistrationSummary ERROR', error);
    }
};