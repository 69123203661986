import {useEffect, useRef} from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import '../../components.scss';

am4core.useTheme(am4themes_animated);
am4core.addLicense("ch-custom-attribution")

export default function Pie({data, value, category, width, height, name, radius=100}) {
    const chart = useRef(null);

    useEffect(() => {
        let pie = am4core.create(name, am4charts.PieChart3D);
        pie.radius = am4core.percent(radius);

        pie.hiddenState.properties.opacity = 0;

        pie.legend = new am4charts.Legend();
        pie.legend.valueLabels.template.fontWeight = "600";
        pie.legend.valueLabels.template.fontSize = 16;
        pie.legend.valueLabels.template.fill = am4core.color("#4A4C54");
        pie.legend.position = "bottom";
        pie.legend.maxColumns = 1;

        pie.legend.labels.template.text = "{category} - {value.percent.formatNumber('#.0')}%";
        pie.legend.valueLabels.template.text = "({value})";
        pie.data = data;

        let series = pie.series.push(new am4charts.PieSeries());
        series.dataFields.value = value;
        series.dataFields.category = category;
        series.labels.template.text = null;
        series.slices.template.propertyFields.fill = "color";

        chart.current = pie;
        return () => {
            pie.dispose();
        };
    });
    return (
        <div
            className={"pie"}
            id={name}
            style={{ width, height }}
        />
    );
};