import { useEffect, useState } from "react";
import windowSize from "../../utils/windowSize";
import HeaderProfile from "./HeaderProfile/HeaderProfile";
import {Container} from "../../components";
import "./Header.scss";

const Header = ({user, region, logout, isSidebarHide, setSidebarHide}) => {
    const [openBurger, setOpenBurger] = useState(false);

    useEffect(() => {
        windowSize(() => setOpenBurger(false));
    }, []);
    return (
        <header className="header">
            <Container>
                <nav className="header__nav">
                    <img className="header__logo" src="/assets/icons/logo-primary.svg" alt="IQanat"/>
                    {
                        user && <HeaderProfile
                            openBurger={openBurger}
                            setOpenBurger={setOpenBurger}
                            user={user}
                            region={region}
                            isSidebarHide={isSidebarHide}
                            setSidebarHide={setSidebarHide}
                            logout={logout}
                        />
                    }
                </nav>
            </Container>
        </header>
    )
}

export default Header;